<template>
  <div class="admin-auth">
    <!-- Full page content -->
    <div v-if="isAuthedLoaded" key="authed" class="w-full">
      <NavDrawer />
      <div class="px-6 py-8 space-y-6 lg:ml-[300px] lg:min-h-screen">
        <GlobalBreadcrumb />
        <!-- The page slot -->
        <div>
          <slot></slot>
        </div>
      </div>

      <!-- Overlay for serviceAdmin with no services -->
      <DashboardNoServiceAccessOverlay v-if="isOverlayShowing" />
    </div>

    <!--    Loading state if auth is still initializing-->
    <FullScreenLoading v-if="authState === 'init'" />

    <!--    Sign in page if not authed-->
    <div v-if="authState === 'not-authed'" class="flex justify-center items-center w-full h-screen">
      <div class="w-[340px]">
        <SignInEmailAuth v-if="isEmailAuth" />
        <SignIn v-else />
      </div>
    </div>

    <UNotifications />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import SignInEmailAuth from '~/components/SignInEmailAuth.vue'

const isEmailAuth = computed(() => Boolean(useRoute().query?.emailAuth))

const authStore = useAuthStore()
const { authState, userDoc } = storeToRefs(authStore)
const isOverlayShowing = computed(() => {
  if (isAuthedLoaded.value && userDoc.value) {
    return userDoc.value.role === 'serviceAdmin' && userDoc.value.services.length === 0
  }
  return false
})

const isAuthedLoaded = computed(() => {
  return authState.value === 'authed' && Boolean(userDoc.value)
})
</script>
