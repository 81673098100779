<template>
  <div
    class="fixed top-0 left-0 h-screen w-screen bg-white bg-opacity-90 flex items-center justify-center z-50"
  >
    <div class="flex flex-col items-center">
      <Logo class="w-48 mb-8" invert />
      <h1 class="text-2xl font-bold mb-4">You do not have access to any services</h1>
      <p>Please contact Hub of Hope support</p>
    </div>
  </div>
</template>
